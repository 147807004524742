<template>
  <div class="app">
    <input type="file" @change="onFileChange" />
    <b-table :data="parents" hoverable>
      <b-table-column field="#" label="#" width="40" numeric v-slot="props">
        {{ parents.indexOf(props.row) + 1 }}
      </b-table-column>

      <b-table-column label="Family Name" v-slot="props">
        {{ props.row.family_name }}
      </b-table-column>

      <b-table-column label="Father's Name" v-slot="props">{{
        props.row.father_name
      }}</b-table-column>

      <b-table-column label="Father's Phone" v-slot="props">{{
        props.row.father_phone
      }}</b-table-column>

      <b-table-column label="Father's Email" v-slot="props">
        {{ props.row.father_email }}
      </b-table-column>

      <b-table-column label="Mother's Name" v-slot="props">{{
        props.row.mother_name
      }}</b-table-column>

      <b-table-column label="Mother's Phone" v-slot="props">{{
        props.row.mother_phone
      }}</b-table-column>

      <b-table-column label="Mother's Email" v-slot="props">
        {{ props.row.mother_email }}
      </b-table-column>
    </b-table>
    <button
      type="submit"
      class="button btn-120 is-primary is-capitalized is-pulled-right mt-3"
      @click="initializeBulkUpload()"
    >
      Submit
    </button>
    <app-modal
      id="bulk-upload-student"
      context="add"
      @add="bulkUpload"
      :show-modal.sync="isModalOpen"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'App',
  data() {
    return {
      schoolId: null,
      parents: [],
    }
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createInput(files[0])
    },
    createInput(file) {
      let promise = new Promise((resolve, reject) => {
        var reader = new FileReader()
        var vm = this
        reader.onload = (e) => {
          resolve((vm.fileinput = reader.result))
        }
        reader.readAsText(file)
      })

      promise.then(
        (result) => {
          const rows = result.split('\r\n')
          rows.shift(0)
          rows.forEach((item) => {
            const [
              family_name,
              father_name,
              father_phone,
              father_email,
              mother_name,
              mother_phone,
              mother_email,
            ] = item.split(',')

            this.parents.push({
              family_name: family_name.trim(),
              father_name: father_name.trim(),
              father_phone: father_phone.trim(),
              father_email: father_email.trim(),
              mother_name: mother_name.trim(),
              mother_phone: mother_phone.trim(),
              mother_email: mother_email.trim(),
            })
          })
        },
        (error) => {
          /* handle an error */
          console.log(error)
        }
      )
    },
    initializeBulkUpload() {
      this.openModal()
    },
    bulkUpload() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ParentsBulkUploadQuery($school_id: Int!, $parents: JSON!) {
              parentsBulkUpload(
                input: { schoolId: $school_id, parents: $parents }
              ) {
                errors
              }
            }
          `,
          variables: {
            school_id: this.schoolId,
            parents: this.parents,
          },
        })
        .then((response) => {
          console.log(response)
          crudNotification(
            response.data.parentsBulkUpload.errors,
            'Successfully updated.'
          )
          this.isModalOpen = false
          this.$router.replace(`/school/${this.schoolId}/parents`)
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', 'Bulk Upload')
    this.$store.commit('setSubMenus', [
      {
        name: 'Parents',
        route: `/school/${this.schoolId}/parents`,
      },
    ])
  },
}
</script>
